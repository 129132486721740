const Badge = {
  baseStyle: {
    fontWeight: 'bold',
    px: '.75rem',
    py: '.1rem',
    fontSize: '.75rem',
    borderRadius: 'full',
  },
};

export default Badge;
