import theme from '../theme';

const Input = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {},
    addon: {},
  },
  variants: {
    outline: {
      field: {
        borderRadius: 'sm',
        borderColor: 'gray.200',
        _hover: {
          borderColor: 'gray.500',
        },
      },
      addon: {
        borderRadius: 'sm',
        background: 'gray.100',
      },
    },
    solid: {
      field: {
        borderRadius: 'sm',
        borderColor: 'gray.200',
        _hover: {
          borderColor: 'gray.500',
        },
      },
      addon: {
        borderRadius: 'sm',
        background: 'gray.100',
      },
    },
    date: (props) => ({
      field: {
        ...theme.components.Input.variants.outline(props).field,
        _hover: {
          borderColor: 'gray.500',
        },
        _readOnly: {
          boxShadow: 'inherit',
        },
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export default Input;
