/* eslint-disable react/prop-types */
import {
  createBrowserRouter,
  Navigate,
} from 'react-router-dom';

import { useSelector } from 'react-redux';
import Login from './views/Login';
import ResetPassword from './views/ResetPassword';
import ForgotPassword from './views/ForgotPassword';
import Dashboard from './views/Dashboard';
import UsersList from './views/users/UsersList';
import NewsList from './views/news/NewsList';
import NotificationsList from './views/notifications/NotificationsList';
import ArticleEdit from './views/news/ArticleEdit';
import TenantsList from './views/tenants/TenantsList';
import NotFound from './views/errors/NotFound';
import NoAccess from './views/errors/NoAccess';

// eslint-disable-next-line react/prop-types
function ProtectedRoute({ allowedRoles = [], children }) {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  // If not logged in then send them to the login page
  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  // Check if user is allowed accessing the route
  if (isLoggedIn && !allowedRoles.includes(user.role)) {
    return <Navigate to="/no-access" replace />;
  }

  return children;
}

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute allowedRoles={['global-admin', 'tenant-admin']}><Dashboard /></ProtectedRoute>,
  },
  {
    path: '/users',
    element: <ProtectedRoute allowedRoles={['global-admin', 'tenant-admin']}><UsersList /></ProtectedRoute>,
  },
  {
    path: '/news',
    element: <ProtectedRoute allowedRoles={['global-admin']}><NewsList /></ProtectedRoute>,
  },
  {
    path: '/news/new-article',
    element: <ProtectedRoute allowedRoles={['global-admin']}><ArticleEdit /></ProtectedRoute>,
  },
  {
    path: '/news/:newsId/edit',
    element: <ProtectedRoute allowedRoles={['global-admin']}><ArticleEdit /></ProtectedRoute>,
  },
  {
    path: '/notifications',
    element: <ProtectedRoute allowedRoles={['global-admin']}><NotificationsList /></ProtectedRoute>,
  },
  {
    path: '/tenants',
    element: <ProtectedRoute allowedRoles={['global-admin']}><TenantsList /></ProtectedRoute>,
  },
  {
    path: '/',
    element: <Navigate to="/dashboard" replace />,
  },
  {
    path: '/not-found',
    element: <ProtectedRoute><NotFound /></ProtectedRoute>,
  },
  {
    path: '/no-access',
    element: <NoAccess />,
  },
  {
    path: '*',
    element: <Navigate to="/not-found" replace />,
  },
]);

export default router;
