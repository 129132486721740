/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  PinInputField,
  PinInput,
  HStack,
  Text,
  Box,
  useToast,
  // PinInput,
  // PinInputField,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useCreateTenantMutation } from '~/services/api';

import useYupValidationResolver from '~/hooks/useYupValidationResolver';

const validationSchema = yup.object({
  tenantName: yup.string().required('Tenant name is required').min(3, 'Must be more than 3 characters'),
});

const newTenant = {
  tenantName: '',
};

function EditTenant({
  isOpen, onClose, tenantData, tenantsList,
}) {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register, getValues, reset, handleSubmit, formState: { errors },
  } = useForm({ resolver, defaultValues: { ...newTenant } });
  const [newTenantCode, setNewTenantCode] = useState(null);
  const isEditing = !!Object.keys(tenantData).length;
  const modalTitle = isEditing ? 'Edit Tenant' : 'Add Tenant';
  const submitButton = isEditing ? 'Update Tenant' : 'Add Tenant';
  const toast = useToast();
  const allTenantCodes = [...new Set(tenantsList?.map((x) => x.tenantCode))];

  const [createTenant, { isLoading }] = useCreateTenantMutation();

  const onSubmit = async () => {
    const formValues = getValues();
    let submission = {
      ...formValues,
    };
    if (!isEditing) {
      // Add a new tenant
      submission = {
        ...submission,
        tenantCode: newTenantCode,
      };
      await createTenant(submission)
        .unwrap()
        .then(() => {
          onClose();
          toast({
            title: 'Success',
            description: 'The tenant was successfully created.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: 'Error',
            description: 'The tenant could not be created, please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  // Generate unique tenant code
  const handleGenerateCode = () => {
    let isUnique = false;
    let code = null;
    while (!isUnique) {
      code = Math.floor(1000 + Math.random() * 9000);
      if (!allTenantCodes.includes(code)) {
        isUnique = true;
        break;
      }
    }
    setNewTenantCode(code.toString());
  };

  useMemo(() => {
    if (isOpen === true) {
      handleGenerateCode();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  // Set editing tenant values
  useEffect(() => {
    reset({ tenantName: tenantData.tenantName, tenantCode: tenantData.tenantCode });
  }, [tenantData, reset]);

  // Reset form when modal is closed
  useEffect(() => {
    if (isOpen === false) {
      reset({ ...newTenant });
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <FormControl mb={6} isInvalid={errors.tenantName}>
              <FormLabel htmlFor="tenantName">Tenant Name</FormLabel>
              <Input id="tenantName" {...register('tenantName')} />
              <FormErrorMessage>{errors.tenantName && errors.tenantName.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.tenantCode}>
              <FormLabel htmlFor="tenantCode">Tenant Code</FormLabel>
              <HStack>
                <PinInput defaultValue={newTenantCode}>
                  <PinInputField readOnly />
                  <PinInputField readOnly />
                  <PinInputField readOnly />
                  <PinInputField readOnly />
                </PinInput>
              </HStack>
            </FormControl>
          </form>
          <Box p={4} background="blue.50" borderRadius="sm">
            <Text size="sm">Please contact Company-X support to have an admin assigned to this tenant</Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose} size="md">
            Cancel
          </Button>
          <Button size="md" onClick={handleSubmit(onSubmit)} isLoading={isLoading}>{submitButton}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

EditTenant.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tenantData: PropTypes.object.isRequired,
  tenantsList: PropTypes.array.isRequired,
};

export default EditTenant;
