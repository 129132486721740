const Select = {
  parts: ['field'],
  baseStyle: {
    field: {},
  },
  variants: {
    outline: {
      field: {
        borderRadius: 'sm',
        borderColor: 'gray.200',
        _hover: {
          cursor: 'pointer',
          borderColor: 'gray.500',
        },
      },
    },
    solid: {
      field: {
        borderRadius: 'sm',
        borderColor: 'gray.200',
        _hover: {
          cursor: 'pointer',
        },
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export default Select;
