const Textarea = {
  baseStyle: {},
  variants: {
    outline: {
      borderRadius: 'sm',
      borderColor: 'gray.200',
      _hover: {
        borderColor: 'gray.500',
      },
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'outline',
  },
};

export default Textarea;
