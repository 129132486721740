import { S3Client } from '@aws-sdk/client-s3';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import config from '~/config';
import { getConfiguredAuth } from './auth';

const getS3Client = async () => {
  const authProvider = `cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.USER_POOL_ID}`;
  const AmplifyAuth = getConfiguredAuth();
  const session = await AmplifyAuth.currentSession();
  const currentToken = session.getIdToken().getJwtToken();
  const client = new S3Client({
    region: config.s3.region,
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region: config.cognito.REGION }),
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      logins: {
        [authProvider]: currentToken,
      },
    }),
  });
  return client;
};

export default getS3Client;
