const colors = {
  brand: {
    primary: {
      50: '#e9f2ff',
      100: '#cbd4e8',
      200: '#abb8d3',
      300: '#7C8EB8', // Primary Blue
      400: '#6b7fad',
      500: '#526594',
      600: '#3f4f74',
      700: '#2c3854',
      800: '#192235',
      900: '#050b18',
    },
  },
  gray: {
    50: '#f1f4fb',
    100: '#d6d9dd',
    200: '#bdbfc1',
    300: '#a3a5a8',
    400: '#898a8e',
    500: '#6f7075',
    600: '#56575b',
    700: '#46474B', // Brand Dark Gray
    800: '#25252a',
    900: '#0b0c14',
  },
  green: {
    50: '#e5fde5',
    100: '#bdf3bf',
    200: '#95ea98',
    300: '#6be170',
    400: '#44d948',
    500: '#2cbf30',
    600: '#219524',
    700: '#156a19',
    800: '#09400d',
    900: '#001600',
  },
  blue: {
    50: '#e9f2ff',
    100: '#cbd4e8',
    200: '#abb8d3',
    300: '#7C8EB8', // Primary Blue
    400: '#6b7fad',
    500: '#526594',
    600: '#3f4f74',
    700: '#2c3854',
    800: '#192235',
    900: '#050b18',
  },
};

export default colors;
