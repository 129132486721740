/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';
import {
  Container,
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import {
  HiOutlineMegaphone,
} from 'react-icons/hi2';
import * as yup from 'yup';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import {
  Select,
} from 'chakra-react-select';
import { format } from 'date-fns';

import { useDispatch } from 'react-redux';

import { useCreateNotificationMutation, useFetchNotificationsQuery, useFetchTenantsQuery } from '~/services/api';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';

const validationSchema = yup.object({
  title: yup.string().required('Title is required').min(10, 'Must be at least 10 characters').max(60, 'Must be no more than 60 characters'),
  body: yup.string().required('Message is required').min(10, 'Must be at least 10 characters').max(120, 'Must be no more than 120 characters'),
  recipients: yup.array().min(1, 'At least one tenant is required').required('At least one tenant is required'),
});

function NotificationsList() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { data: tenants, refetch: refetchTenants } = useFetchTenantsQuery();
  const { data, isLoading, refetch } = useFetchNotificationsQuery();
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register, handleSubmit, formState: { errors }, watch, getValues, control, setValue, reset,
  } = useForm({ resolver, defaultValues: { title: '', body: '', recipients: [] } });
  const toast = useToast();
  const titleValue = watch('title');
  const messageValue = watch('body');
  const recipientsValue = watch('recipients');
  const tenantOptions = tenants?.map((t) => ({ label: t.tenantName, value: t.tenantId }));
  const isAllSelected = tenantOptions?.length === recipientsValue?.length;

  const [createNotification, { isLoading: isSubmitting }] = useCreateNotificationMutation();

  useEffect(() => {
    dispatch(refetchTenants);
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    const { title, body, recipients } = getValues();
    const notification = {
      title, body, type: 'info',
    };
    await createNotification({ notification, recipients: recipients.map((r) => r.value) })
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: 'Notification was sent successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        reset();
        onClose();
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: 'Notification could not be sent. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleTenantSelect = () => {
    if (!isAllSelected && tenantOptions) {
      setValue('recipients', [...tenantOptions]);
    } else {
      setValue('recipients', []);
    }
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  return (
    <PrimaryLayout pageTitle="Notifications">
      <PageHeader pageTitle="Notifications" isFlex>
        <Button leftIcon={<HiOutlineMegaphone size={22} />} ml={4} onClick={onOpen}>Send Notification</Button>
      </PageHeader>
      <Container maxW="container.xl" py={8} display="flex" flexGrow={1} flexDirection="column">
        {isLoading
          && <Flex flexGrow={1} w="100%" justify="center" align="center"><Spinner /></Flex>}
        {!isLoading && (!data || data?.length === 0)
          && <Text>No notifications have been sent.</Text>}
        {!isLoading && data?.length > 0
        && (
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Message</Th>
                <Th>Date Sent</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((notification) => (
                <Tr key={`notification-${notification.notificationId}`}>
                  <Td>{notification.title}</Td>
                  <Td>{notification.body}</Td>
                  <Td>
                    {format(new Date(notification.sendTimestamp), 'dd/MM/yyyy')}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        )}
      </Container>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>New Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <Controller
                control={control}
                name="recipients"
                render={({
                  field: {
                    onChange, onBlur, value, name, ref,
                  },
                  fieldState: { error },
                }) => (
                  <FormControl mb={6} isInvalid={errors.recipients}>
                    <Flex justify="space-between" align="center" mb={4}>
                      <FormLabel htmlFor="title">Recipients</FormLabel>
                      <Button onClick={handleTenantSelect} size="sm" colorScheme="gray">{isAllSelected ? 'Deselect All' : 'Select All'}</Button>
                    </Flex>
                    <Select
                      useBasicStyles
                      selectedOptionStyle="check"
                      isMulti
                      isFixed
                      hideSelectedOptions={false}
                      placeholder="Select tenants..."
                      size="md"
                      name={name}
                      ref={ref}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      options={tenantOptions ? [...tenantOptions] : []}
                    />
                    <FormErrorMessage>{error && error.message}</FormErrorMessage>
                  </FormControl>
                )}
              />
              <FormControl mb={6} isInvalid={errors.title}>
                <Flex justify="space-between" mb={2} align="center">
                  <FormLabel htmlFor="title">Title</FormLabel>
                  <Flex borderRadius="full" background="gray.50" align="center" px={2} py={1}>
                    <Text fontSize="xs">
                      {titleValue?.length}
                      /60
                    </Text>
                  </Flex>
                </Flex>
                <Input id="title" {...register('title')} maxLength="60" />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </FormControl>
              <FormControl mb={6} isInvalid={errors.body}>
                <Flex justify="space-between" mb={2} align="center">
                  <FormLabel htmlFor="body">Message</FormLabel>
                  <Flex borderRadius="full" background="gray.50" align="center" px={2} py={1}>
                    <Text fontSize="xs">
                      {messageValue?.length}
                      /120
                    </Text>
                  </Flex>
                </Flex>
                <Textarea id="body" {...register('body')} rows={3} maxLength="120" />
                <FormErrorMessage>{errors.body && errors.body.message}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={handleCancel}>
              Cancel
            </Button>
            <Button onClick={handleSubmit(onSubmit)} isLoading={isSubmitting}>Send Notification</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </PrimaryLayout>
  );
}

export default NotificationsList;
