/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, FormControl, Stack, Input, Button, FormLabel, FormErrorMessage, Text, Spacer,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';

import { completeNewPassword } from '~/helpers/auth';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import AuthLayout from '~/components/layouts/AuthLayout';

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*-_]{10,}$/,
      'Password must be 10 characters long, contain at least one uppercase letter, one lowercase letter, and one number',
    ),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
});

function ResetPassword() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const resolver = useYupValidationResolver(schema);

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver,
  });

  const handleChangePassword = async (passwords) => {
    if (!user) {
      // eslint-disable-next-line no-console
      console.error('No user found');
      return;
    }
    await completeNewPassword(user, passwords.newPassword);
    navigate('/login');
  };

  return (
    <AuthLayout pageTitle="Forgot Password">
      <Box p={10} w="500px">
        <Box background="white" borderRadius="lg" p={6} shadow={8}>
          <Text fontSize="2xl" fontWeight="bold" mb={6}>Reset Password</Text>
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                value={user?.challengeParam?.userAttributes?.email}
                isReadOnly
              />
            </FormControl>
            <FormControl isInvalid={errors.newPassword}>
              <Stack>
                <FormLabel>New Password</FormLabel>
                <Input
                  type="password"
                  {...register('newPassword')}
                />
                <FormErrorMessage>{errors.newPassword && errors.newPassword.message}</FormErrorMessage>
              </Stack>
            </FormControl>
            <FormControl isInvalid={errors.confirmPassword}>
              <Stack>
                <FormLabel>Confirm New Password</FormLabel>
                <Input
                  type="password"
                  {...register('confirmPassword')}
                />
                <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
              </Stack>
            </FormControl>
            <Stack direction="row" width="100%" justifyContent="space-between" pt={4}>
              <Spacer />
              <Button onClick={handleSubmit(handleChangePassword)}>Change Password</Button>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default ResetPassword;
