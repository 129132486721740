import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers(['tablist', 'tab']);

const Tabs = helpers.defineMultiStyleConfig({
  baseStyle: {},
  variants: {
    'soft-rounded': defineStyle(({ colorScheme: c }) => ({
      tab: {
        color: 'gray.600',
        marginRight: 2,
        paddingX: 6,
        backgroundColor: 'rgba(0,0,0,0.025)',
        _selected: {
          color: `${c}.700`,
          bg: `${c}.100`,
        },
        _hover: {
          color: `${c}.500`,
        },
      },
    })),
  },
  defaultProps: {
    size: 'md',
    variant: 'soft-rounded',
  },
});

export default Tabs;
