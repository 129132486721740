/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { attributes } = action.payload;
      state.isLoggedIn = true;
      state.user = {
        userId: attributes.sub,
        email: attributes.email,
        name: attributes.name,
      };
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setProfile(state, action) {
      const {
        role, tenant, tenantId, tenantCode,
      } = action.payload;
      state.user = {
        ...state.user, role, tenant, tenantId, tenantCode,
      };
    },
  },
});

export const {
  login, logout, setUser, setProfile,
} = authSlice.actions;
export default authSlice.reducer;
