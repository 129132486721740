/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Box, FormControl, Stack, Input, Button, FormLabel, Text, InputGroup, InputRightElement, IconButton, Icon,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HiEye, HiEyeSlash } from 'react-icons/hi2';

import { loginWithAmplify, getConfiguredAuth, logoutWithAmplify } from '~/helpers/auth';
import {
  login, setUser, setProfile, logout,
} from '~/store/auth';
import { useFetchProfileMutation } from '~/services/api';
import AuthLayout from '~/components/layouts/AuthLayout';

function Login() {
  const location = useLocation();
  const [username, setUsername] = useState(location?.state?.email);
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [fetchProfile, { isLoading }] = useFetchProfileMutation();
  const canSubmit = username && password;
  const [error, setError] = useState('');

  const handleShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const auth = getConfiguredAuth();

    if (auth) {
      auth.currentAuthenticatedUser()
        .then(async (user) => {
          dispatch(login(user));
          try {
            const profile = await fetchProfile().unwrap();
            if (profile) {
              dispatch(setProfile(profile));
              navigate('/dashboard');
            }
          } catch (e) {
            // There was an error fetching the profile, so log the user out
            console.error('Profile fetch error:', e);
            setError('There was an error logging you in. Please try again later.');
            await logoutWithAmplify();
            dispatch(logout());
          }
        })
        .catch(() => {});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async () => {
    setError('');
    let user;
    try {
      user = await loginWithAmplify(username, password);
    } catch (e) {
      setError('Incorrect email or password');
      console.error('Login error:', e);
      return;
    }

    // If the user requires a new password, navigate to the reset password page
    if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch(setUser(user));
      navigate('/reset-password');
      return;
    }

    // If the login was successful, proceed to fetch the profile and navigate to the dashboard
    if (user) {
      dispatch(login(user));
      try {
        const profile = await fetchProfile().unwrap();
        if (profile) {
          dispatch(setProfile(profile));
          navigate('/dashboard');
        }
      } catch (e) {
        // There was an error fetching the profile, so log the user out
        console.error('Profile fetch error:', e);
        setError('There was an error logging you in. Please try again later.');
        await logoutWithAmplify();
        dispatch(logout());
      }
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleLogin();
  };

  return (
    <AuthLayout pageTitle="Login">
      <Box p={10} w="500px">
        <Box background="white" borderRadius="lg" p={6} shadow={8}>
          <Text fontSize="2xl" fontWeight="bold" mb={4}>Login to your Account</Text>
          {error
              && <Text mb={4} color="red" fontSize="sm">{error}</Text>}
          <form onSubmit={handleFormSubmit}>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  value={username}
                  onChange={(event) => setUsername(event.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <InputRightElement>
                    <IconButton
                      size="sm"
                      onClick={handleShowPassword}
                      colorScheme="gray"
                      icon={<Icon as={showPassword ? HiEye : HiEyeSlash} boxSize={5} color="gray.600" />}
                      variant="ghost"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack direction="row" width="100%" justifyContent="space-between">
                <Button variant="ghost" _text="white" onClick={() => navigate('/forgot-password', { state: { email: username } })}>Forgot password?</Button>
                <Button type="submit" isLoading={isLoading} isDisabled={!canSubmit}>Login</Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default Login;
