const Button = {
  baseStyle: {
    fontWeight: 'bold',
    textDecoration: 'none',
    lineHeight: 1,
  },
  variants: {
    link: {
      color: 'brand.secondary.400',
      textTransform: 'unset',
      borderBottom: '2px solid transparent',
    },
    linkActive: {
      borderBottom: '2px solid',
      paddingLeft: '0px',
      paddingRight: '0px',
      borderRadius: 'md',
      textTransform: 'unset',
    },
    unstyled: {
      height: 'auto',
      _hover: {
        opacity: 0.8,
      },
    },
  },
  colorScheme: {
    white: {
      background: 'white',
    },
  },
  sizes: {
    lg: {
      fontSize: 'md',
      borderRadius: 'md',
    },
    md: {
      fontSize: 'sm',
      borderRadius: 'sm',
    },
    sm: {
      fontSize: 'xs',
      borderRadius: 'sm',
    },
    xs: {
      fontSize: 'xs',
      borderRadius: 'sm',
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'brand.primary',
  },
};

export default Button;
