import { useEffect, useState, useRef } from 'react';
import {
  Container,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  Icon,
  Spinner,
  useDisclosure,
  Badge,
  Box,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from '@chakra-ui/react';
import {
  HiMagnifyingGlass, HiXMark, HiPlus,
} from 'react-icons/hi2';

import { useFetchTenantsQuery, useDisableTenantMutation, useEnableTenantMutation } from '~/services/api';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';
import { TENANT_STATUS_COLORS, TENANT_STATUSES } from '~/utils/constants';
import TabFilters from '~/components/shared/TabFilters';
import EditTenant from './components/EditTenant';

function TenantsList() {
  const { data, isLoading, refetch } = useFetchTenantsQuery();
  const [filteredTenants, setFilteredTenants] = useState([]);
  const { onOpen: onOpenModal, isOpen: isModalOpen, onClose: onCloseModal } = useDisclosure();
  const { onOpen: onOpenConfirm, isOpen: isConfirmOpen, onClose: onCloseConfirm } = useDisclosure();
  const cancelRef = useRef();
  const [editingTenant, setEditingTenant] = useState({});
  const [filter, setFilter] = useState('');
  const [filteredStatus, setFilteredStatus] = useState('all');
  const toast = useToast();

  const [disableTenant, { isLoading: isDisabling }] = useDisableTenantMutation();
  const [enableTenant] = useEnableTenantMutation();

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      setFilteredTenants(
        data,
      );
    }
  }, [data]);

  useEffect(() => {
    if (data?.length > 0) {
      setFilteredTenants(
        data
          .filter((t) => (filteredStatus !== 'all' ? t.status === filteredStatus : t))
          .filter(
            (t) => t.tenantName?.toLowerCase().startsWith(filter.toLowerCase()),
          )
          .sort((a, b) => a.tenantName.localeCompare(b.tenantName)),
      );
    }
  }, [data, filter, filteredStatus]);

  const handleDisableTenant = async () => {
    const { tenantId } = editingTenant;
    await disableTenant(tenantId)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: 'The tenant was successfully disabled.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setEditingTenant({});
        onCloseConfirm();
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'The tenant could not be disabled, please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleEnableTenant = async (tenantId) => {
    await enableTenant(tenantId)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: 'The tenant was successfully enabled.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'The tenant could not be enabled, please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleShowConfirm = (tenant) => {
    setEditingTenant({ ...tenant });
    onOpenConfirm();
  };

  const handleCloseConfirm = () => {
    setEditingTenant({});
    onCloseConfirm();
  };

  const handleCloseModal = () => {
    setEditingTenant({});
    onCloseModal();
  };

  return (
    <PrimaryLayout pageTitle="Tenants">
      <PageHeader pageTitle="Tenants" pb={0}>
        <Box mt={8}>
          <Flex justify="space-between" align="center">
            <TabFilters
              filterList={[{ label: 'All', value: 'all' }, ...TENANT_STATUSES.map((status) => ({ label: status, value: status }))]}
              defaultFilter="all"
              callback={(status) => setFilteredStatus(status)}
            />
            <Flex align="center">
              <Button leftIcon={<HiPlus size={22} />} ml={4} onClick={onOpenModal}>Add Tenant</Button>
            </Flex>
          </Flex>
        </Box>
      </PageHeader>
      <Container maxW="container.xl" py={8} display="flex" flexGrow={1} flexDirection="column">
        <Flex align="center" justifyContent="space-between" pb={4}>
          <InputGroup w="auto" minW="40%">
            <InputLeftElement pointerEvents="none">
              <Icon as={HiMagnifyingGlass} />
            </InputLeftElement>
            <Input
              type="text"
              placeholder="Find a tenant by name..."
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            />
            {filter
            && (
            <InputRightElement>
              <IconButton aria-label="Clear Filter" icon={<Icon as={HiXMark} w={6} h={6} />} size="sm" onClick={() => setFilter('')} variant="ghost" />
            </InputRightElement>
            )}
          </InputGroup>
        </Flex>
        {isLoading
          && <Flex flexGrow={1} w="100%" justify="center" align="center"><Spinner /></Flex>}
        {!isLoading && (!data || data?.length === 0)
          && <Text>No tenants available</Text>}
        {!isLoading && filteredTenants.length > 0
        && (
        <>
          <Text fontSize="sm" pb={4}>
            Showing
            {' '}
            {filteredTenants.length}
            {' '}
            of
            {' '}
            {data.length}
            {' '}
            tenants
          </Text>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Tenant Name</Th>
                  <Th>Tenant Code</Th>
                  <Th>Admins</Th>
                  <Th>Status</Th>
                  <Th>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredTenants.map((tenant) => (
                  <Tr key={`tenant-${tenant.tenantName}`}>
                    <Td>{tenant.tenantName}</Td>
                    <Td>{tenant.tenantCode}</Td>
                    <Td>{tenant.admins?.join(',')}</Td>
                    <Td><Badge colorScheme={TENANT_STATUS_COLORS[tenant.status]}>{tenant.status}</Badge></Td>
                    <Td>
                      {tenant.tenantName !== 'Guest'
                        && (
                          tenant.status === 'active' ? (
                            <Button onClick={() => handleShowConfirm(tenant)} size="xs" variant="ghost" colorScheme="red">
                              Disable Tenant
                            </Button>
                          ) : (
                            <Button onClick={() => handleEnableTenant(tenant.tenantId)} size="xs" variant="ghost">
                              Enable Tenant
                            </Button>
                          )
                        )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
        )}
        <EditTenant isOpen={isModalOpen} onClose={() => handleCloseModal()} tenantData={editingTenant} tenantsList={data} />
        <AlertDialog
          isOpen={isConfirmOpen}
          leastDestructiveRef={cancelRef}
          onClose={onCloseConfirm}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Disable
                {' '}
                {editingTenant.tenantName}
                ?
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure you want to disable
                {' '}
                <Text as="span" fontWeight="bold">{editingTenant.tenantName}</Text>
                ? All users associated with the tenant will be disabled also.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={handleCloseConfirm} colorScheme="gray">
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={handleDisableTenant} isLoading={isDisabling} ml={3}>
                  Disable Tenant
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Container>
    </PrimaryLayout>
  );
}

export default TenantsList;
