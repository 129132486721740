import {
  Container, Flex, Spinner, Text,
  Stat,
  StatLabel,
  StatNumber,
  HStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';

import { useFetchDetailQuery } from '~/services/api';
import { useSelector } from 'react-redux';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';

function Dashboard() {
  const { data, isLoading, refetch } = useFetchDetailQuery();
  const { user } = useSelector((state) => state.auth);
  console.log(data);
  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dateToday = new Date();
  // Display greeting depending on time of day
  const greeting = () => {
    const curHr = dateToday.getHours();
    if (curHr < 12) {
      return 'Mōrena,';
    }
    if (curHr < 18) {
      return 'Kia ora,';
    }
    return 'Kia ora,';
  };

  const renderStats = () => {
    if (user.role !== 'global-admin') {
      return (
        <HStack spacing={4}>
          <Stat borderColor="gray.50" borderWidth="1px" padding={4} borderRadius="md">
            <StatLabel>Tenant Code</StatLabel>
            <StatNumber>{data?.tenantCode}</StatNumber>
          </Stat>
          <Stat borderColor="gray.50" borderWidth="1px" padding={4} borderRadius="md">
            <StatLabel>No. of Users</StatLabel>
            <StatNumber>{data?.userCount}</StatNumber>
          </Stat>
        </HStack>
      );
    }
    return (
      <HStack spacing={4}>
        <Stat borderColor="gray.50" borderWidth="1px" padding={4} borderRadius="md">
          <StatLabel>No. of Users</StatLabel>
          <StatNumber>{data?.userCount}</StatNumber>
        </Stat>
        <Stat borderColor="gray.50" borderWidth="1px" padding={4} borderRadius="md">
          <StatLabel>No. of Tenants</StatLabel>
          <StatNumber>{data?.tenantCount}</StatNumber>
        </Stat>
      </HStack>
    );
  };

  return (
    <PrimaryLayout pageTitle="Dashboard">
      <PageHeader pageTitle="Dashboard" />
      <Container maxW="container.xl" py={8} display="flex" flexGrow={1} flexDirection="column">
        {isLoading
          && <Flex flexGrow={1} w="100%" justify="center" align="center"><Spinner /></Flex>}
        {!isLoading
          && (
          <>
            <Text mb={8} fontWeight="bold" fontSize="2xl">
              {greeting()}
              {' '}
              {user.name}
            </Text>
            {renderStats()}
          </>
          )}
      </Container>
    </PrimaryLayout>
  );
}

export default Dashboard;
