const Table = {
  parts: ['table', 'thead', 'tbody', 'tr', 'th', 'td', 'caption'],
  baseStyle: {
    th: {
      fontWeight: 'bold',
      fontFamily: 'Montserrat',
    },
  },
  variants: {
  },
};

export default Table;
