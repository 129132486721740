import { useEffect, useState, useRef } from 'react';
import {
  Container,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Button,
  Badge,
  Link,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Spinner,
  AspectRatio,
} from '@chakra-ui/react';
import {
  HiOutlineDocumentPlus,
} from 'react-icons/hi2';
import { format } from 'date-fns';

import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import config from '~/config';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';
import TabFilters from '~/components/shared/TabFilters';

import { ARTICLE_STATUS_COLORS, ARTICLE_STATUSES } from '~/utils/constants';
import { useFetchAllArticlesQuery, useRemoveArticleMutation } from '~/services/api';

function NewsList() {
  const navigate = useNavigate();
  const { data: news, isLoading, refetch } = useFetchAllArticlesQuery();
  const [filteredStatus, setFilteredStatus] = useState('all');
  const [filteredNews, setFilteredNews] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [deletingArticle, setDeletingArticle] = useState(undefined);
  const toast = useToast();

  const [deleteArticle, { isLoading: isDeleting }] = useRemoveArticleMutation();

  useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredNews(
      news?.filter((article) => (filteredStatus !== 'all' ? article.status === filteredStatus : article))
        .sort((a, b) => new Date(b.date) - new Date(a.date)),
    );
  }, [news, filteredStatus]);

  const handleDelete = async () => {
    await deleteArticle(deletingArticle.newsId)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: 'The article was successfully deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setDeletingArticle(undefined);
        onClose();
      })
      .catch(() => {
        toast({
          title: 'Error',
          description: 'The article could not be deleted, please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  return (
    <PrimaryLayout pageTitle="News">
      <PageHeader pageTitle="News" pb={0}>
        <Box mt={8}>
          <Flex justify="space-between" align="center">
            <TabFilters
              filterList={[{ label: 'All', value: 'all' }, ...ARTICLE_STATUSES.map((status) => ({ label: status, value: status }))]}
              defaultFilter="all"
              callback={(status) => setFilteredStatus(status)}
            />
            <Flex align="center">
              <Button leftIcon={<HiOutlineDocumentPlus size={22} />} ml={4} onClick={() => navigate('/news/new-article')}>Add Article</Button>
            </Flex>
          </Flex>
        </Box>
      </PageHeader>
      <Container maxW="container.xl" py={8} display="flex" flexGrow={1} flexDirection="column">
        {isLoading
          && <Flex flexGrow={1} w="100%" justify="center" align="center"><Spinner /></Flex>}
        {!isLoading && filteredNews?.length === 0
          && <Text>No articles available</Text>}
        {!isLoading && filteredNews?.length > 0
        && (
        <>
          <Text fontSize="sm" pb={4}>
            Showing
            {' '}
            {filteredNews.length}
            {' '}
            of
            {' '}
            {news.length}
            {' '}
            articles
          </Text>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>&nbsp;</Th>
                  <Th>Title</Th>
                  <Th>Author</Th>
                  <Th>Status</Th>
                  <Th>Date</Th>
                  <Th>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredNews.map((article) => (
                  <Tr key={`article-${article.newsId}`}>
                    <Td>
                      <AspectRatio w="150px">
                        <Image src={`${config.s3.publicUrl}/${article.imageSrc}`} objectFit="cover" alt={article.title} />
                      </AspectRatio>
                    </Td>
                    <Td>
                      <Link as={RouterLink} to={`/news/${article.newsId}/edit`} border="none!important" display="flex" alignItems="center">
                        {article.title}
                      </Link>
                    </Td>
                    <Td>
                      {article.author}
                    </Td>
                    <Td><Badge colorScheme={ARTICLE_STATUS_COLORS[article.status]}>{article.status}</Badge></Td>
                    <Td>
                      {format(new Date(article.date), 'dd/MM/yyyy')}
                    </Td>
                    <Td>
                      <Flex align="center" justify="flex-end">
                        <Button onClick={() => navigate(`/news/${article.newsId}/edit`)} size="xs" variant="ghost" mr={4}>Edit</Button>
                        <Button onClick={() => { setDeletingArticle(article); onOpen(); }} size="xs" variant="ghost" colorScheme="red">Delete</Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
        )}
      </Container>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Article
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this article?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={() => handleDelete()} ml={3} isLoading={isDeleting}>
                Delete Article
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </PrimaryLayout>
  );
}

export default NewsList;
