import { Auth } from '@aws-amplify/auth';
import config from '../config';

let configuredAmplify;
export const getConfiguredAuth = () => {
  if (!configuredAmplify) {
    Auth.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      },
    });
    configuredAmplify = Auth;
  }
  return configuredAmplify;
};

export const logoutWithAmplify = async () => getConfiguredAuth().signOut();
export const changePassword = async (user, oldPassword, newPassword) => getConfiguredAuth().changePassword(user, oldPassword, newPassword);
export const completeNewPassword = async (user, newPassword) => getConfiguredAuth().completeNewPassword(user, newPassword);
export const forgotPassword = async (username) => getConfiguredAuth().forgotPassword(username.trim());
export const forgotPasswordSubmit = async (username, code, newPassword) => getConfiguredAuth().forgotPasswordSubmit(username.trim(), code, newPassword);
export const loginWithAmplify = async (username, password) => getConfiguredAuth().signIn(username.trim(), password);
export const getCurrentUser = async () => getConfiguredAuth().currentAuthenticatedUser();
export const getJwtToken = async () => (await getConfiguredAuth().currentSession()).getIdToken().getJwtToken();
