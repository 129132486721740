import { Container, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';

function NoAccess() {
  const { isLoggedIn } = useSelector((state) => state.auth);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return (
    <PrimaryLayout pageTitle="No Access">
      <PageHeader pageTitle="No Access" />
      <Container maxW="container.xl" py={8}>
        <Text>Sorry you don&apos;t have access to the page you requested.</Text>
      </Container>
    </PrimaryLayout>
  );
}

export default NoAccess;
