import { extendTheme } from '@chakra-ui/react';
import Alert from './components/alert';
import Link from './components/link';
import Button from './components/button';
import Heading from './components/heading';
import Text from './components/text';
import Table from './components/table';
import Badge from './components/badge';
import Input from './components/input';
import Select from './components/select';
import NumberInput from './components/numberInput';
import Checkbox from './components/checkbox';
import Radio from './components/radio';
import Textarea from './components/textarea';
import Tabs from './components/tabs';
import FormLabel from './components/formLabel';
import colors from './foundations/colors';
import container from './foundations/sizes';
import typography from './foundations/typography';

export default extendTheme({
  colors,
  sizes: { container },
  ...typography,
  components: {
    Alert, Input, Select, NumberInput, Checkbox, Radio, Table, Tabs, Textarea, Badge, Button, Heading, Text, Link, FormLabel,
  },
});
