/* eslint-disable import/prefer-default-export */
export const ARTICLE_STATUSES = ['draft', 'published'];
export const ARTICLE_STATUS_COLORS = {
  published: 'green',
  draft: 'gray',
};
export const USER_STATUSES = ['CONFIRMED'];
export const USER_STATUS_COLORS = {
  CONFIRMED: 'green',
};
export const TENANT_STATUSES = ['active', 'inactive'];
export const TENANT_STATUS_COLORS = {
  active: 'green',
  inactive: 'gray',
};
