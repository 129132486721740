/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { Heading, Flex, Container } from '@chakra-ui/react';

function PageHeader({
  pageTitle, children, containerWidth, isFlex, ...other
}) {
  const flexCss = {
    display: 'flex',
    align: 'center',
    justifyContent: 'space-between',
  };

  return (
    <Flex as="header" py={12} w="100%" backgroundColor="gray.50" {...other}>
      <Container maxW={containerWidth} sx={isFlex ? flexCss : null}>
        <Heading as="h1">{pageTitle}</Heading>
        {children}
      </Container>
    </Flex>
  );
}

PageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  containerWidth: PropTypes.string,
  children: PropTypes.any,
  isFlex: PropTypes.bool,
};

PageHeader.defaultProps = {
  children: undefined,
  containerWidth: 'container.xl',
  isFlex: false,
};

export default PageHeader;
