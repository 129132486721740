/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Checkbox,
  Flex,
  Box,
  Badge,
  useToast,
  Image,
} from '@chakra-ui/react';
import * as yup from 'yup';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { useForm } from 'react-hook-form';
import {
  useCreateArticleMutation, useCreateNotificationMutation, useFetchArticleQuery, useUpdateArticleMutation,
} from '~/services/api';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';
import MediaUploader from '~/components/shared/MediaUploader';
import config from '~/config';
import { ARTICLE_STATUS_COLORS } from '~/utils/constants';

const validationSchema = yup.object({
  title: yup.string().required('Title is required'),
  author: yup.string().required('Author is required'),
  abstract: yup.string().required('Abstract is required'),
  body: yup.string().required('Body is required'),
  date: yup.date().typeError('Must be a valid date').required('Date is required'),
  imageSrc: yup.string().required('An image is required'),
});

const newArticle = {
  title: '',
  abstract: '',
  body: '',
  author: '',
  date: format(new Date(), 'yyyy-MM-dd'),
  imageSrc: '',
};

function ArticleEdit() {
  const navigate = useNavigate();
  const { newsId } = useParams();
  const user = useSelector((state) => state.auth.user);
  const { data: article, isLoading: isLoadingArticle } = useFetchArticleQuery(newsId);
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register, reset, handleSubmit, formState: { errors }, getValues, setValue,
  } = useForm({ resolver, defaultValues: { ...newArticle, author: user.name } });
  const [uploadedImageSrc, setUploadedImageSrc] = useState('');
  const isEditing = !!Object.keys(article || {}).length;
  const pageTitle = isEditing ? 'Edit Article' : 'Add Article';
  // eslint-disable-next-line no-nested-ternary
  const submitButton = isEditing ? (article?.status === 'draft' ? 'Publish Article' : 'Update Article') : 'Publish Article';
  const [saveType, setSaveType] = useState('');
  const [newArticleId, setNewArticleId] = useState(undefined);
  const [sendNotification, setSendNotification] = useState(false);
  const toast = useToast();

  const [createNotification, { isLoading: isSubmittingNotification }] = useCreateNotificationMutation();
  const [createArticle, { isLoading: isCreating }] = useCreateArticleMutation();
  const [updateArticle, { isLoading: isUpdating }] = useUpdateArticleMutation();

  const isLoading = isCreating || isUpdating;

  const onSubmit = async (status) => {
    setSaveType(status);
    const formValues = getValues();
    let submission = {
      ...formValues,
      date: format(parseISO(formValues.date), 'yyyy-MM-dd'),
      status,
    };
    if (!isEditing) {
      // New Article
      submission = {
        ...submission,
        userId: user.userId,
      };
      await createArticle(submission)
        .unwrap()
        .then((result) => {
          toast({
            title: 'Success',
            description: 'The article was successfully created.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setNewArticleId(result.articleId);
          navigate('/news');
        })
        .catch(() => {
          toast({
            title: 'Error',
            description: 'The article could not be created, please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    } else {
      // Updating an article
      delete submission.newsId;
      delete submission.userId;
      await updateArticle({ article: submission, newsId })
        .unwrap()
        .then(() => {
          navigate('/news');
          toast({
            title: 'Success',
            description: 'The article was successfully updated.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch(() => {
          toast({
            title: 'Error',
            description: 'The article could not be updated, please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
    // Send notification
    if (sendNotification && status === 'published') {
      const { title } = getValues();
      const notification = {
        title: 'Check out the latest news from Union Square', body: title, type: 'news', itemId: newsId || newArticleId,
      };
      await createNotification({ notification, recipients: [] })
        .unwrap()
        .then(() => {
          toast({
            title: 'Notification Sent',
            description: 'All users were notified of this article.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((e) => {
          console.log(e);
          toast({
            title: 'Notification Error',
            description: 'Notification could not be sent. Please try again.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    }
    setSaveType('');
  };

  const handleMediaUpload = (key) => {
    setValue('imageSrc', key);
    setUploadedImageSrc(key);
  };

  // Set editing article values
  useEffect(() => {
    if (isEditing) {
      reset({ ...article });
      setUploadedImageSrc(article.imageSrc);
    }
  }, [article, isEditing, reset]);

  return (
    <PrimaryLayout pageTitle={pageTitle}>
      <PageHeader pageTitle={pageTitle} containerWidth="container.lg" />
      <Box background="gray.50" position="sticky" top={0} zIndex={100}>
        <Container maxW="container.lg" py={4}>
          <Flex justify="space-between">
            <Flex align="center">
              <Button colorScheme="gray" mr={3} onClick={() => navigate('/news')} size="md">
                Cancel
              </Button>
            </Flex>
            <Flex justify="space-between" align="center">
              <Button
                size="md"
                mr={3}
                onClick={handleSubmit(() => onSubmit('draft'))}
                variant="outline"
                isLoading={isLoading && saveType === 'draft'}
                isDisabled={isLoading}
              >
                Save Draft
              </Button>
              <Button
                size="md"
                onClick={handleSubmit(() => onSubmit('published'))}
                isLoading={(isLoading || isSubmittingNotification) && saveType === 'published'}
                isDisabled={isLoading}
              >
                {submitButton}
              </Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
      <Container maxW="container.lg" py={8}>
        {!isLoadingArticle
          && (
          <form>
            {article?.status
            && (
            <FormControl mb={6}>
              <FormLabel>Status</FormLabel>
              <Badge colorScheme={ARTICLE_STATUS_COLORS[article.status]}>{article.status}</Badge>
            </FormControl>
            )}
            <FormControl mb={6} isInvalid={errors.title}>
              <FormLabel htmlFor="title">Title</FormLabel>
              <Input id="title" {...register('title')} />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.date} w={200}>
              <FormLabel htmlFor="date">Date</FormLabel>
              <Input id="date" {...register('date')} type="date" />
              <FormErrorMessage>{errors.date && errors.date.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.imageSrc}>
              <FormLabel>Main Image</FormLabel>
              {uploadedImageSrc
              && (
                <Image src={`${config.s3.publicUrl}/${uploadedImageSrc}`} w="150px" mb={4} />
              )}
              <MediaUploader
                onUpload={(key) => handleMediaUpload(key)}
                allowMultiple={false}
                allowedFileTypes={{ 'image/*': ['.jpeg', '.png'] }}
                allowedMessage="JPG or PNG. Maximum size of 50MB"
                buttonLabel={article?.imageSrc ? 'Upload New Image' : 'Upload Image'}
              />
              <FormErrorMessage>{errors.imageSrc && errors.imageSrc.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={6} mb={6} isInvalid={errors.author}>
              <FormLabel htmlFor="author">Author</FormLabel>
              <Input id="author" {...register('author')} />
              <FormErrorMessage>{errors.author && errors.author.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.abstract}>
              <FormLabel htmlFor="abstract">Abstract</FormLabel>
              <Textarea id="abstract" {...register('abstract')} rows={3} />
              <FormErrorMessage>{errors.abstract && errors.abstract.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.body}>
              <FormLabel htmlFor="body">Body</FormLabel>
              <Textarea id="body" {...register('body')} rows={14} />
              <FormErrorMessage>{errors.body && errors.body.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6}>
              <Checkbox value={sendNotification} onChange={(e) => setSendNotification(e.target.checked)}>Notify users of this article?</Checkbox>
            </FormControl>
          </form>
          )}
      </Container>
    </PrimaryLayout>
  );
}

export default ArticleEdit;
