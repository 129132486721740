/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Flex, Link, List, ListItem, Icon, Image, Text, Tooltip, useTheme, Button,
} from '@chakra-ui/react';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  HiOutlineHome, HiHome, HiOutlineArrowTopRightOnSquare, HiOutlineUsers, HiUsers, HiOutlineNewspaper, HiNewspaper, HiArrowRightOnRectangle, HiUserCircle,
  HiBellAlert, HiOutlineBellAlert, HiOutlineBuildingStorefront, HiBuildingStorefront,
} from 'react-icons/hi2';
import Logo from '~/assets/images/symbol.svg';
import { useDispatch, useSelector } from 'react-redux';

import { logoutWithAmplify } from '~/helpers/auth';
import { logout } from '~/store/auth';

function NavLink({
  // eslint-disable-next-line react/prop-types
  icon, activeIcon, name, isExternal, url,
}) {
  const theme = useTheme();
  const location = useLocation();
  const activeStyle = {
    position: 'relative',
    color: theme.colors.brand.primary[300],
    background: theme.colors.gray[900],
  };
  const isActive = location.pathname.startsWith(url);
  return (
    <Tooltip label={name} placement="right" hasArrow visibility={{ base: 'visible', xl: 'hidden' }}>
      <Link
        as={isExternal ? null : RouterLink}
        target={isExternal ? '_blank' : ''}
        to={url}
        borderBottom="none"
        padding="3"
        pl={{ base: '2', xl: '7' }}
        display="flex"
        alignItems="center"
        justifyContent={{ base: 'center', xl: 'space-between' }}
        fontWeight="bold"
        fontSize="md"
        color="gray.100"
        borderRadius="md"
        _hover={{ background: 'gray.900' }}
        style={isActive ? activeStyle : undefined}
        mb={2}
      >
        <Box display="flex" align="center" position="relative">
          <Icon as={isActive ? activeIcon : icon} boxSize={6} display="block" />
          <Text as="span" color="inherit" display={{ base: 'none', xl: 'block' }} ml={5}>{name}</Text>
        </Box>
        {isExternal && <Icon as={HiOutlineArrowTopRightOnSquare} boxSize="12px" color="gray.400" />}
      </Link>
    </Tooltip>
  );
}

function AppSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    await logoutWithAmplify();
    dispatch(logout());
    navigate('/login');
  };
  const navList = [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: HiOutlineHome,
      activeIcon: HiHome,
      allowedRoles: ['global-admin', 'tenant-admin'],
    },
    {
      name: 'Tenants',
      url: '/tenants',
      icon: HiOutlineBuildingStorefront,
      activeIcon: HiBuildingStorefront,
      allowedRoles: ['global-admin'],
    },
    {
      name: 'Users',
      url: '/users',
      icon: HiOutlineUsers,
      activeIcon: HiUsers,
      allowedRoles: ['global-admin', 'tenant-admin'],
    },
    {
      name: 'News',
      url: '/news',
      icon: HiOutlineNewspaper,
      activeIcon: HiNewspaper,
      allowedRoles: ['global-admin'],
    },
    {
      name: 'Notifications',
      url: '/notifications',
      icon: HiOutlineBellAlert,
      activeIcon: HiBellAlert,
      allowedRoles: ['global-admin'],
    },
  ];
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  return (
    <Flex as="aside" backgroundColor="gray.800" position="relative" h="100%" direction="column" justify="space-between">
      <Box position="sticky" top="0px" w="inherit">
        <Flex as="header" px={4} py={8} align="center" justify="flex-start">
          <Link as={RouterLink} to="/" border="none!important" display="flex" alignItems="center">
            <Image src={Logo} w={{ base: '4rem', xl: '4rem' }} alt="Union Square Logo" />
            <Box as="span" ml={4} display={{ base: 'none', xl: 'block' }}>
              <Text color="white" fontFamily="Comfortaa" fontWeight="bold" fontSize="lg">UNION SQUARE</Text>
              <Text color="gray.100" fontSize="sm">ADMIN PORTAL</Text>
            </Box>
          </Link>
        </Flex>
        <Box px={2} mt={4}>
          {isLoggedIn
          && (
          <List>
            {navList.filter((x) => x.allowedRoles.includes(user.role)).map(({
              icon, activeIcon, url, name,
            }) => (
              <ListItem key={name}>
                <NavLink icon={icon} activeIcon={activeIcon} url={url} name={name} />
              </ListItem>
            ))}
          </List>
          )}
        </Box>
      </Box>
      <Box background="rgba(255,255,255,0.05)" position="sticky" bottom="0px">
        {isLoggedIn
        && (
          <>
            <Box py={3} px={6}>
              <Text color="gray.50" fontSize="2xl" noOfLines={1} fontWeight="bold">
                {user.role !== 'global-admin' ? user?.tenant : 'Global Admin'}
              </Text>
              {user.role !== 'global-admin' && (
              <Text fontSize="md" color="gray.400">
                Code:
                {' '}
                <Text as="span" color="gray.200" fontWeight="bold">{user.tenantCode}</Text>
              </Text>
              )}
            </Box>
            <Flex align="center" p={4}>
              <Icon as={HiUserCircle} boxSize={12} color="gray.200" />
              <Box ml={2} display={{ base: 'none', xl: 'block' }}>
                <Text color="white" fontWeight="bold" p={0}>
                  {user?.name || 'Unknown Name'}
                </Text>
              </Box>
            </Flex>
            <Box px={4} pb={4}>
              <Tooltip label="Logout" placement="right" hasArrow visibility={{ base: 'visible', xl: 'hidden' }}>
                <Button
                  variant="unstyled"
                  colorScheme="red"
                  onClick={handleLogout}
                  w="100%"
                  borderBottom="none"
                  padding="3"
                  pl={3}
                  display="flex"
                  alignItems="center"
                  justifyContent={{ base: 'center', xl: 'space-between' }}
                  fontWeight="bold"
                  fontSize="md"
                  color="gray.100"
                  borderRadius="md"
                  _hover={{ background: 'gray.900' }}
                >
                  <Box display="flex" align="center" position="relative">
                    <Icon as={HiArrowRightOnRectangle} boxSize={6} display="block" />
                    <Text as="span" color="inherit" display={{ base: 'none', xl: 'block' }} ml={5}>Logout</Text>
                  </Box>
                </Button>
              </Tooltip>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
}

export default AppSidebar;
