const typography = {
  fonts: {
    heading: 'Comfortaa, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
};

export default typography;
