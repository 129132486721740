/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import { Box, Grid } from '@chakra-ui/react';
import useTitle from '~/hooks/useTitle';
import AppSidebar from '~/components/shared/AppSidebar';

function PrimaryLayout({ children, pageTitle, ...other }) {
  useTitle(pageTitle);
  return (
    <Grid templateColumns={{ base: 'minmax(0, 6rem) minmax(0, 1fr)', xl: 'minmax(0, 18rem) minmax(0, 1fr)' }} h="100vh">
      <AppSidebar />
      <Box as="main" role="main" backgroundColor="white" display="flex" flexDirection="column" flexGrow={1} {...other}>
        {children}
      </Box>
    </Grid>
  );
}

PrimaryLayout.propTypes = {
  children: PropTypes.any.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default PrimaryLayout;
