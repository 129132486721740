export default Object.freeze({
  cognito: {
    REGION: process.env.REACT_APP_AWS_REGION,
    USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
  s3: {
    bucketName: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_AWS_REGION,
    publicUrl: process.env.REACT_APP_S3_URL,
  },
  environment: process.env.REACT_APP_ENVIRONMENT,
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
});
