const Checkbox = {
  parts: ['control'],
  baseStyle: {},
  defaultProps: {
    size: 'md',
    colorScheme: 'brand.primary',
  },
};

export default Checkbox;
