/* eslint-disable react/jsx-props-no-spreading */
import {
  useCallback, useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
  Flex, Input, Spinner, Text, Icon, Button,
} from '@chakra-ui/react';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { HiCamera, HiCheckCircle, HiCloudArrowUp } from 'react-icons/hi2';
import uuid from 'react-uuid';

import config from '~/config';
import getS3Client from '~/helpers/s3';

function MediaUploader({
  onUpload, allowMultiple, allowedFileTypes, allowedMessage, buttonLabel,
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState('');
  const inputRef = useRef();

  // eslint-disable-next-line consistent-return
  const uploadFile = async (file) => {
    setIsUploading(true);
    const { name } = file;
    const extension = name.slice(name.lastIndexOf('.'));
    const key = uuid() + extension;
    const uploadParams = {
      Bucket: config.s3.bucketName,
      Key: key,
      Body: file,
    };
    const s3Client = await getS3Client();
    try {
      const data = await s3Client.send(new PutObjectCommand(uploadParams));
      onUpload(key);
      setFileName(name);
      setIsUploading(false);
      return data;
    } catch (err) {
      // console.log('Error', err);
      setError(true);
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setError(false);
    const promises = acceptedFiles.map(async (file) => {
      const result = await uploadFile(file);
      return result;
    });
    await Promise.all(promises);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    getRootProps, getInputProps, isDragActive, fileRejections,
  } = useDropzone({
    onDrop,
    accept: allowedFileTypes,
  });

  useEffect(() => {
    if (error) {
      setIsUploading(false);
    }
  }, [error]);

  return (
    <>
      {!allowMultiple && (
        <>
          <Input onChange={() => onDrop(Array.from(inputRef.current.files))} type="file" accept={allowedFileTypes} ref={inputRef} hidden />
          <Button
            leftIcon={!isUploading ? <Icon as={HiCloudArrowUp} w={6} h={6} /> : null}
            isLoading={isUploading}
            size="md"
            colorScheme="gray"
            variant="outline"
            onClick={() => inputRef.current.click()}
          >
            {buttonLabel}
          </Button>
          {fileName && (
            <Flex mt={2} align="center">
              <Icon as={HiCheckCircle} w={6} h={6} color="green" />
              <Text fontSize="sm" ml={2}>
                {fileName}
              </Text>
            </Flex>
          )}
          {error && (
            <Text textAlign="center" color="red" status="error" mb={4}>
              There was an error uploading the file. Please try again
            </Text>
          )}
        </>
      )}

      {allowMultiple && (
        <Flex
          {...getRootProps()}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={8}
          minHeight="100px"
          borderColor="gray.100"
          borderWidth="1px"
          borderStyle="dashed"
          borderRadius="md"
          _hover={{
            borderColor: 'brand.secondary.400',
          }}
        >
          {isUploading ? (
            <Flex boxSize="6rem" alignItems="center" justifyContent="center" mb={8}>
              <Spinner emptyColor="gray.50" color="gray.200" size="xl" thickness="4px" />
            </Flex>
          ) : (
            <Icon as={HiCamera} boxSize="6rem" color="gray.200" mb={8} />
          )}

          <Input {...getInputProps()} accept={allowedFileTypes} />
          {isDragActive ? <Text textAlign="center">Drop files here</Text> : <Text textAlign="center">Drag and drop files here, or click to select</Text>}
          {allowedMessage && (
            <Text color="gray.200" fontSize="xs" mt={2}>
              {allowedMessage}
            </Text>
          )}

          {error && (
            <Text textAlign="center" color="red" status="error" mt={4}>
              There was an error uploading the file. Please try again
            </Text>
          )}

          {fileRejections.length > 0 && (
            <Text textAlign="center" color="red" status="error" mt={4}>
              File type is incorrect.
            </Text>
          )}
        </Flex>
      )}
    </>
  );
}

MediaUploader.propTypes = {
  onUpload: PropTypes.func.isRequired,
  allowMultiple: PropTypes.bool.isRequired,
  allowedFileTypes: PropTypes.object.isRequired,
  allowedMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
};

MediaUploader.defaultProps = {
  allowedMessage: null,
  buttonLabel: 'Upload Image',
};

export default MediaUploader;
