import {
  configureStore, combineReducers, createAction,
} from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { api } from '~/services/api';
import authReducer from './auth';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [api.reducerPath],
};

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat([thunk, api.middleware]),
});

export const persistor = persistStore(store);
export const userLogout = createAction('USER_LOGOUT');

export default store;
