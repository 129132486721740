import { useEffect, useState } from 'react';
import {
  Container,
  Text,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  Icon,
  Spinner,
  useDisclosure,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import {
  HiMagnifyingGlass, HiXMark, HiEllipsisHorizontal, HiCheck, HiNoSymbol,
} from 'react-icons/hi2';

import {
  useFetchUsersQuery,
  useDisableUserMutation,
  useEnableUserMutation,
  useRemoveUserMutation,
  usePromoteUserMutation,
  useDemoteUserMutation,
} from '~/services/api';
import { useSelector } from 'react-redux';
import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';
import TabFilters from '~/components/shared/TabFilters';
import EditUser from './components/EditUser';

const sortingRoles = ['global-admin', 'tenant-admin', 'member'];

function UsersList() {
  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { data, isLoading } = useFetchUsersQuery();
  const [filteredRole, setFilteredRole] = useState('all');
  // Only show available roles derived from the the returned data
  const roles = [...new Set(data?.users?.map((x) => x.role))].sort((a, b) => sortingRoles.indexOf(a) - sortingRoles.indexOf(b));
  const [filteredUsers, setFilteredUsers] = useState([]);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [editingUser, setEditingUser] = useState({});
  const [filter, setFilter] = useState('');
  const toast = useToast();

  const [disableUser] = useDisableUserMutation();
  const [enableUser] = useEnableUserMutation();
  const [removeUser] = useRemoveUserMutation();
  const [promoteUser] = usePromoteUserMutation();
  const [demoteUser] = useDemoteUserMutation();

  useEffect(() => {
    if (data?.users.length > 0) {
      setFilteredUsers([...data.users]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data?.users.length > 0) {
      setFilteredUsers(
        data?.users
          .filter(
            (user) => (filteredRole !== 'all' ? user.role === filteredRole : user),
          )
          .filter(
            (u) => u.name?.toLowerCase().startsWith(filter.toLowerCase()) || u.email?.toLowerCase().startsWith(filter.toLowerCase()) || u.tenantName?.toLowerCase().startsWith(filter.toLowerCase()),
          ),
      );
    }
  }, [data, filter, filteredRole]);

  const handleEditUser = (user) => {
    setEditingUser(user);
    onOpen();
  };

  const handleDisableUser = async (user) => {
    await disableUser(user.id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `${user.name} was disabled.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: `${user.name} could not be disabled. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleEnableUser = async (user) => {
    await enableUser(user.id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `${user.name} was enabled.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: `${user.name} could not be enabled. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleRemoveUser = async (user) => {
    await removeUser(user.id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `${user.name} was removed from the tenant.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: `${user.name} could not be removed. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handlePromoteUser = async (user) => {
    await promoteUser(user.id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `${user.name} was promoted to tenant admin.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: `${user.name} could not be promoted. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleDemoteUser = async (user) => {
    await demoteUser(user.id)
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `${user.name} was demoted to member.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: `${user.name} could not be demoted. Please try again.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleClose = () => {
    setEditingUser({});
    onClose();
  };

  const pageTitle = () => {
    let title = 'Users';
    if (loggedInUser.role === 'tenant-admin') {
      title = `${loggedInUser.tenant} ${title}`;
    }
    return title;
  };

  return (
    <PrimaryLayout pageTitle={pageTitle()}>
      <PageHeader pageTitle={pageTitle()} pb={0}>
        <Box mt={8}>
          <Flex justify="space-between" align="center">
            <TabFilters
              filterList={[{ label: 'All', value: 'all' }, ...roles.map((role) => ({ label: role.replace('-', ' '), value: role }))]}
              defaultFilter="all"
              callback={(role) => { setFilteredRole(role); }}
            />
          </Flex>
        </Box>
      </PageHeader>
      <Container maxW="container.xl" py={8} display="flex" flexGrow={1} flexDirection="column">
        <Flex align="center" justifyContent="space-between" pb={4}>
          <InputGroup w="auto" minW="40%">
            <InputLeftElement pointerEvents="none">
              <Icon as={HiMagnifyingGlass} />
            </InputLeftElement>
            <Input
              type="text"
              placeholder={`Find a user by name or email ${loggedInUser.role === 'global-admin' ? 'or tenant name' : ''}...`}
              value={filter}
              onChange={(e) => setFilter(e.currentTarget.value)}
            />
            {filter
            && (
            <InputRightElement>
              <IconButton aria-label="Clear Filter" icon={<Icon as={HiXMark} w={6} h={6} />} size="sm" onClick={() => setFilter('')} variant="ghost" />
            </InputRightElement>
            )}
          </InputGroup>
        </Flex>
        {isLoading
          && <Flex flexGrow={1} w="100%" justify="center" align="center"><Spinner /></Flex>}
        {!isLoading && filteredUsers.length === 0
          && <Text>No users available</Text>}
        {!isLoading && filteredUsers.length > 0
        && (
        <>
          <Text fontSize="sm" pb={4}>
            Showing
            {' '}
            {filteredUsers.length}
            {' '}
            of
            {' '}
            {data.users.length}
            {' '}
            users
          </Text>
          <TableContainer>
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Tenant</Th>
                  <Th>Email</Th>
                  <Th>Phone</Th>
                  <Th>Role</Th>
                  <Th>Enabled</Th>
                  <Th>&nbsp;</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredUsers.map((user) => (
                  <Tr key={`usr-${user.email}`}>
                    <Td>{user.name}</Td>
                    <Td>{user.tenantName || 'N/A'}</Td>
                    <Td>{user.email}</Td>
                    <Td>{user.phone || 'N/A'}</Td>
                    <Td><Text textTransform="capitalize" fontSize="sm">{user.role.replace('-', ' ')}</Text></Td>
                    <Td>
                      {user.isEnabled ? <Icon as={HiCheck} w={4} h={4} color="green" /> : <Icon as={HiNoSymbol} w={4} h={4} color="red" />}
                    </Td>
                    <Td>
                      <Menu>
                        <>
                          <MenuButton
                            as={IconButton}
                            aria-label="More"
                            icon={<HiEllipsisHorizontal size={24} />}
                            variant="ghost"
                          />
                          <MenuList>
                            <MenuItem onClick={() => handleEditUser(user)}>Edit User</MenuItem>
                            {loggedInUser.role === 'global-admin' && user.role === 'member'
                            && (
                              <MenuItem onClick={() => handlePromoteUser(user)}>
                                Promote to Tenant Admin
                              </MenuItem>
                            )}
                            {loggedInUser.role === 'global-admin' && user.role === 'tenant-admin'
                            && (
                              <MenuItem onClick={() => handleDemoteUser(user)}>
                                Demote to Member
                              </MenuItem>
                            )}
                            {user.isEnabled
                            && (
                            <MenuItem onClick={() => handleDisableUser(user)}>
                              Disable User
                            </MenuItem>
                            )}
                            {!user.isEnabled
                            && (
                            <MenuItem onClick={() => handleEnableUser(user)}>
                              Enable User
                            </MenuItem>
                            )}
                            {user.tenantName !== 'Guest'
                            && <MenuItem onClick={() => handleRemoveUser(user)}>Remove from Tenant</MenuItem>}
                          </MenuList>
                        </>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
        )}
        <EditUser isOpen={isOpen} onClose={() => handleClose()} userData={editingUser} />
      </Container>
    </PrimaryLayout>
  );
}

export default UsersList;
