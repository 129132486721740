/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputLeftAddon,
  InputGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import { useEffect } from 'react';

import { useUpdateUserMutation } from '~/services/api';

const validationSchema = yup.object({
  name: yup.string().required('Full name is required').min(3, 'Must be more than 3 characters'),
  email: yup.string().email('Must be a valid email').required('Email is required'),
  phone: yup.string().matches(/^[0-9]+$/, 'Please enter numbers only').min(8, 'Please enter a valid phone number').max(15, 'Must be less than 15 digits'),
});

const newUser = {
  name: '',
  email: '',
  phone: '',
};

function EditUser({ isOpen, onClose, userData }) {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    register, reset, handleSubmit, getValues, formState: { errors },
  } = useForm({ resolver, defaultValues: { ...newUser } });
  const isEditing = !!Object.keys(userData).length;
  const modalTitle = isEditing ? 'Edit User' : 'Add User';
  const submitButton = isEditing ? 'Update User' : 'Add User';
  const toast = useToast();

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  const onSubmit = async () => {
    const { name, phone } = getValues();
    const body = { name, phone };
    await updateUser({ userId: userData.id, body })
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: 'User was updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      })
      .catch((e) => {
        console.log(e);
        toast({
          title: 'Error',
          description: 'User could not be updated. Please try again.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
  };

  // Set editing user values
  useEffect(() => {
    reset({ name: userData.name, email: userData.email, phone: userData.phone?.replace('+64', '') });
  }, [userData, reset]);

  // Reset form when modal is closed
  useEffect(() => {
    if (isOpen === false) {
      reset({ ...newUser });
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form>
            <FormControl mb={6} isInvalid={errors.name}>
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <Input id="name" {...register('name')} />
              <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.email} isReadOnly={isEditing} isDisabled={isEditing}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input id="email" {...register('email')} />
              <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
            </FormControl>
            <FormControl mb={6} isInvalid={errors.phone}>
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <InputGroup w="auto" minW="40%">
                <InputLeftAddon>+64</InputLeftAddon>
                <Input id="phone" {...register('phone')} type="text" minLength="3" maxLength="15" />
              </InputGroup>
              <FormErrorMessage>{errors.phone && errors.phone.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="gray" mr={3} onClick={onClose} size="md">
            Cancel
          </Button>
          <Button size="md" onClick={handleSubmit(onSubmit)} isLoading={isLoading}>{submitButton}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

EditUser.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};

export default EditUser;
