import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Flex, Box,
} from '@chakra-ui/react';

function TabFilters({ filterList, defaultFilter, callback }) {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter);

  useEffect(() => {
    callback(selectedFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Flex>
      {filterList.map(({ value, label }) => (
        <Box
          as="button"
          py={6}
          px={6}
          borderBottom="4px solid"
          borderColor={selectedFilter === value ? 'brand.primary.300' : 'transparent'}
          color={selectedFilter === value ? 'gray.800' : 'gray.400'}
          _hover={{ borderColor: 'brand.primary.300' }}
          _active={{
            borderColor: 'brand.primary.300',
          }}
          textTransform="capitalize"
          onClick={() => setSelectedFilter(value)}
          key={value}
          fontWeight="bold"
        >
          {label}
        </Box>
      ))}
    </Flex>
  );
}

TabFilters.propTypes = {
  filterList: PropTypes.array.isRequired,
  callback: PropTypes.func.isRequired,
  defaultFilter: PropTypes.any.isRequired,
};

export default TabFilters;
