import { Container, Text } from '@chakra-ui/react';

import PrimaryLayout from '~/components/layouts/PrimaryLayout';
import PageHeader from '~/components/shared/PageHeader';

function NotFound() {
  return (
    <PrimaryLayout pageTitle="Page Not Found">
      <PageHeader pageTitle="Page Not Found" />
      <Container maxW="container.xl" py={8}>
        <Text>Sorry the page you requested could not be found.</Text>
      </Container>
    </PrimaryLayout>
  );
}

export default NotFound;
