/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import {
  Box, FormControl, Stack, Input, Button, FormLabel, FormErrorMessage, Text, useToast,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';

import { forgotPassword, forgotPasswordSubmit } from '~/helpers/auth';
import useYupValidationResolver from '~/hooks/useYupValidationResolver';
import AuthLayout from '~/components/layouts/AuthLayout';

const schema = Yup.object().shape({
  newPassword: Yup.string()
    .required('New password is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*-_]{10,}$/,
      'Password must be 10 characters long, contain at least one uppercase letter, one lowercase letter, and one number',
    ),
  confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  code: Yup.string().required('Reset code is required'),
});

function ForgotPassword() {
  const location = useLocation();
  const [step2, setStep2] = useState(false); // Step2 is where the user enters the reset code and new password
  const [email, setEmail] = useState(location?.state?.email);
  const [isResetting, setIsResetting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(schema);
  const toast = useToast();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm({
    resolver,
  });

  const handleForgotPassword = async () => {
    setIsResetting(true);
    try {
      await forgotPassword(email);
    } catch (error) {
      // Ignore this case - we don't want users knowing why reset password
      // failed in case it is a malicious attempt to gain an email address
    }

    if (step2) {
      toast({
        title: 'Password reset email sent',
        description: 'Please check your email for a password reset code',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } else {
      setStep2(true);
    }
    setIsResetting(false);
  };

  const handleChangePassword = async (passwords) => {
    setIsUpdating(true);
    try {
      await forgotPasswordSubmit(email, passwords.code, passwords.newPassword);
    } catch (error) {
      toast({
        title: 'Password reset',
        description: 'Failed to reset password, please check your code and try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsUpdating(false);
      return;
    }

    toast({
      title: 'Password reset',
      description: 'Your password has been reset successfully',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });

    setIsUpdating(false);
    navigate('/login', { state: { email } });
  };

  return (
    <AuthLayout pageTitle="Forgot Password">
      <Box p={10} w="500px">
        <Box background="white" borderRadius="lg" p={6} shadow={8}>
          <Text fontSize="2xl" fontWeight="bold" mb={4}>Forgot Password?</Text>
          {!step2
            && <Text mb={6}>Please enter your email address and we will send you reset instructions.</Text>}
          {step2
            && (
            <Text mb={6}>
              We have sent a reset code to
              {' '}
              {email}
              {' '}
            </Text>
            )}
          <Stack spacing={4}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isReadOnly={step2}
              />
            </FormControl>
            {step2 && (
              <>
                <FormControl>
                  <FormLabel>Reset Code</FormLabel>
                  <Input
                    {...register('code')}
                    autoComplete="off"
                  />
                  <FormErrorMessage>{errors.newPassword && errors.newPassword.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.newPassword}>
                  <Stack>
                    <FormLabel>New Password</FormLabel>
                    <Input
                      type="password"
                      autoComplete="off"
                      {...register('newPassword')}
                    />
                    <FormErrorMessage>{errors.newPassword && errors.newPassword.message}</FormErrorMessage>
                  </Stack>
                </FormControl>
                <FormControl isInvalid={errors.confirmPassword}>
                  <Stack>
                    <FormLabel>Confirm New Password</FormLabel>
                    <Input
                      type="password"
                      autoComplete="off"
                      {...register('confirmPassword')}
                    />
                    <FormErrorMessage>{errors.confirmPassword && errors.confirmPassword.message}</FormErrorMessage>
                  </Stack>
                </FormControl>
              </>
            )}
            <Stack direction="row" width="100%" justifyContent="space-between" pt={4}>
              {!step2 && <Button onClick={() => navigate('/login')} variant="ghost">Back to Login</Button>}
              <Button isLoading={isResetting} onClick={(handleForgotPassword)} colorScheme={step2 ? 'gray' : 'blue'} isDisabled={!email}>{step2 ? 'Resend Code' : 'Reset Password'}</Button>
              {step2 && <Button isLoading={isUpdating} onClick={handleSubmit(handleChangePassword)}>Update Password</Button>}
            </Stack>
          </Stack>
        </Box>
      </Box>
    </AuthLayout>
  );
}

export default ForgotPassword;
