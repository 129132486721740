/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import {
  Box, Flex, Image,
} from '@chakra-ui/react';
import useTitle from '~/hooks/useTitle';
import Logo from '~/assets/images/primary-logo.svg';
import BgImage from '~/assets/images/auth-bg.jpeg';

function AuthLayout({ children, pageTitle }) {
  useTitle(pageTitle);
  return (
    <Flex
      flex={1}
      background="gray.900"
      minHeight="100vh"
      height="100%"
    >
      <Flex py={10} direction="column" align="center" justify="center" zIndex={100} w="100%">
        <Image src={Logo} w="12rem" alt="Union Square Logo" />
        {children}
      </Flex>
      <Box
        position="absolute"
        left="0"
        top="0"
        bottom="0"
        right="0"
        zIndex={1}
        minHeight="100vh"
        height="100%"
        overflow="hidden"
      >
        <Image
          resizemode="cover"
          w="100%"
          opacity=".15"
          alt="Union Square"
          src={BgImage}
        />
      </Box>
    </Flex>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
  pageTitle: PropTypes.string.isRequired,
};

export default AuthLayout;
